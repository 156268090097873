<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'admin-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <i class="far fa-user mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account
          :rolesData="rolesOptions"
          :user-data="userData"
          class="mt-2 pt-5"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <i class="fas fa-info mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-5" :user-data="userData" />
      </b-tab>

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import router from "@/router";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabSocial from "./UserEditTabSocial.vue";

import axiosU from "@/core/services/api/admin/user";
import axiosR from "@/core/services/api/admin/roles";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
    const rolesOptions = ref([]);
    const userData = ref(null);


    axiosR.rolesList().then(({ data }) => {
      rolesOptions.value = data.map((item) => {
        const role_id = item.id;
        return { role_id, ...item };
      });
    });

    // Get user data
    axiosU.userDetail(router.currentRoute.params.id).then(({registro}) => {
      const rolesUpdate = registro.roles.map((item) => {
        return item.role_id
      })
      const facilitiesUpdate = registro.facilities.map((item) => {
        const id = item.facilities_id
        return id
      })
      registro.roles = rolesUpdate
      registro.facilities = facilitiesUpdate
      userData.value = registro
    });

    return {
      userData,
      rolesOptions,
    };
  },
};
</script>


